//import packages
import React, { useState } from "react";
import {
  Layout,
  Button,
  Modal,
  Tabs,
  Typography,
  message
} from "antd";

import { PlusCircleOutlined } from '@ant-design/icons'
import styled from "styled-components";
//import other files and API function
import UserRolesAndRightsHOComponent from "../../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import TemplatesTable from "./TemplatesTable";
import TemplateForm from './TemplateForm';
import { PrescriptionTemplates } from "./PrescriptionTemplates"
import { PrescriptionTemplateForm } from "./PrescriptionTemplates/components/PrescriptionTemplateForm";
import { useTemplates } from "./hooks/useTemplates"
import { TelemedicineCategoryProvider } from "../../../context/TelemedicineCategoryContext";

import { TemplateTypeEnum } from "../../../constants/enums";

const { Content } = Layout;
const { Title } = Typography

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 80vw;
  min-height: 80vh;
  margin: 24px auto;
  padding: 24px 28px 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    border-bottom-color: #f1f1f1;
  }
`

const Templates = () => {
  const [templateModalIsOpen, setTemplateModalIsOpen] = useState(false);
  const [type, setType] = useState('case');

  const {
    selectTemplate,
    setTemplates,
    createTemplate,
    editTemplate,
    deleteTemplate,
    setTemplateFilters,
    telemedicineCategories,
    caseStatuses,
    physicians,
    selectedTemplate,
    loading,
    templates,
    templateFilters
  } = useTemplates(type)

  const handleFinish = () => {
    setTemplateModalIsOpen(false)
    selectTemplate(null)
  };

  const _handleFinish = () => {
    handleFinish()
  }

  const handleCreateTemplate = async ({ setLoading, ...values }) => {
    try {
        setLoading(true)
        await createTemplate({ ...values })
        handleFinish()
    } catch (e) {
        message.error(e)
    } finally {
        setLoading(false)
    }
  }

  const handleEditTemplate = async ({ setLoading, ...values }) => {
    try {
        setLoading(true)
        await editTemplate(selectedTemplate._id, values)
        handleFinish()
    } catch (e) {
        console.log(e)
        message.error('Error editing prescription template')
    } finally {
        setLoading(false)
    }
}

  const handleChangeTemplateType = templateType => {
    setTemplates([])
    setType(templateType)
  }

  const handleSubmit = selectedTemplate
    ? handleEditTemplate
    : handleCreateTemplate

  return (
    <TelemedicineCategoryProvider>
      <Content>
      <StyledComponentWrapper>
        <Tabs
          activeKey={type}
          onChange={handleChangeTemplateType}
          tabBarExtraContent={
            <Button
              type='primary'
              icon={<PlusCircleOutlined />}
              onClick={() => setTemplateModalIsOpen(true)}
            >
              Add
            </Button>
          }
        >
          <Tabs.TabPane tab='Case Templates' key={TemplateTypeEnum.CASE}>
            <TemplatesTable
              type={TemplateTypeEnum.CASE}
              templates={templates}
              setTemplateFilters={setTemplateFilters}
              templateFilters={templateFilters}
              caseStatuses={caseStatuses}
              physicians={physicians}
              handleFinish={_handleFinish}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Message Templates' key={TemplateTypeEnum.MESSAGE}>
            <TemplatesTable
              type={TemplateTypeEnum.MESSAGE}
              templates={templates}
              setTemplateFilters={setTemplateFilters}
              templateFilters={templateFilters}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Prescription Templates' key={TemplateTypeEnum.PRESCRIPTION} style={{ height: '100%', width: '100%'}}>
            <PrescriptionTemplates
              templates={templates}
              telemedicineCategories={telemedicineCategories}
              selectedTemplate={selectedTemplate}
              templateFilters={templateFilters}
              loading={loading}
              selectTemplate={selectTemplate}
              editTemplate={editTemplate}
              deleteTemplate={deleteTemplate}
              handleFinish={handleFinish}
              setTemplateFilters={setTemplateFilters}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Visit Note Templates' key={TemplateTypeEnum.VISIT} style={{ height: '100%'}}>
            <TemplatesTable
                type={TemplateTypeEnum.VISIT}
                templates={templates}
                telemedicineCategories={telemedicineCategories}
                setTemplateFilters={setTemplateFilters}
                handleFinish={_handleFinish}
                templateFilters={templateFilters}
            />
          </Tabs.TabPane>
        </Tabs>
      </StyledComponentWrapper>
      { (templateModalIsOpen || selectedTemplate) && (
          <StyledModal
            title={type === TemplateTypeEnum.PRESCRIPTION && <Title level={4} style={{ color: '#7BAD7E' }}>{ selectedTemplate? "Edit" : "Add New"} Prescription Template</Title>}
            maskClosable
            destroyOnClose
            centered
            visible
            closable={false}
            width='50vw'
            footer={null}
            onCancel={() => {
              setTemplateModalIsOpen(false)
              handleFinish()
            }}
          >
            {
              type === TemplateTypeEnum.PRESCRIPTION
                ? <PrescriptionTemplateForm telemedicineCategories={telemedicineCategories} handleFinish={handleFinish} submit={handleSubmit} selectedTemplate={selectedTemplate} />
                : <TemplateForm mode='Add' type={type} handleFinish={_handleFinish} loading={loading}/>
            }
          </StyledModal>
      )}
    </Content>
    </TelemedicineCategoryProvider>

  );
};

export default UserRolesAndRightsHOComponent(Templates);
