import React, { useState, useRef, useEffect, memo } from "react";
import { Button, Input, Row, Col, Tooltip, Dropdown } from "antd";
import { SendOutlined, CheckCircleOutlined, DownOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import styled from "styled-components";

//import components
import MessageComponent from "../CaseMessageComponent/MessageComponent";
import isValidUrl from "../../utils/isValidURL";
import { TicketStatus } from "../../constants/enums";

const { TextArea } = Input;

const StyledMessageWrapper = styled.div`
  height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  background: #e8ecef;
  width: 100%;
`;

const StyledNoMessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  background: #e8ecef;
`;

const StyledButton = styled(Button)`
  padding: 0 8px;
  color: #49548d;
  height: 100%;
  width: 100%;
  opacity: ${({ isDisabled }) => isDisabled ? 0.5 : 1};
  pointer-events: ${({ isDisabled }) => isDisabled ? 'none' : 'auto'};
`;

const MessageList = ({ messages, authorEmail, markTicketAsRead }) => {
  const getName = ({ email, firstName, lastName, type } = {}) => {
    if(email === authorEmail) {
      return "You"
    }
    const fullName = `${firstName} ${lastName}`
    return type ? `${fullName} : ${type}` : fullName;
  }

  if (!messages?.length) {
    return <StyledNoMessageWrapper>No messages yet</StyledNoMessageWrapper>;
  }

  return messages.map((m) => (
    <MessageComponent
      key={m._id}
      name={getName(m.chatOwnerDetails)}
      avatarUrl={
        isValidUrl(m.chatOwnerDetails.profileImage) &&
        m.chatOwnerDetails.profileImage
      }
      isUnread={!m.readBy.includes("admin")}
      markTicketAsRead={markTicketAsRead}
      time={(m.createAt ? moment(m.createAt) : moment()).format("LLL")}
      messageText={m.message}
      imageUrl={m.key || null}
    />
  ))
};

const RenderTooltip = ({ children, show, ...tooltipProps }) => {
  if (!show) {
    return children;
  }
  return (
    <Tooltip {...tooltipProps}>
      <div>
        {children}
      </div>
    </Tooltip>
  )
}

const AdminPhysicianChatListingComponent = ({
  authorEmail,
  sendMessage,
  messages,
  updateTicketStatus,
  markTicketAsRead,
  isAssignedPhysician,
}) => {
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [messageEntered, setMessageEntered] = useState("");
  const messageListingWrapperRef = useRef(null);

  const isSendMessageDisabled = !messageEntered || sendMessageLoading;

  const onClickSendMessageButton = async (event) => {
    event.stopPropagation();
    if (isSendMessageDisabled) {
      return;
    }
    setSendMessageLoading(true);
    const isSuccess = await sendMessage(messageEntered);
    isSuccess && setMessageEntered("");
    setSendMessageLoading(false);
  };

  const handleTextAreaChange = (event) => {
    event.stopPropagation();
    setMessageEntered(event.target.value);
  };

  const scrollToBottom = () => {
    messageListingWrapperRef.current.scrollTop =
      messageListingWrapperRef.current.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <StyledMessageWrapper ref={messageListingWrapperRef}>
        <MessageList messages={messages} authorEmail={authorEmail} markTicketAsRead={markTicketAsRead} />
      </StyledMessageWrapper>
      <RenderTooltip show={!isAssignedPhysician} placement="top" title="Case should be assigned to a physician to send messages">
      <Row>
        <Col span={19}>
          <TextArea
            value={messageEntered}
            placeholder="Send a message"
            onChange={handleTextAreaChange}
            autoSize={{ minRows: 2, maxRows: 2 }}
            onFocus={markTicketAsRead}
            disabled={!isAssignedPhysician}
          />
        </Col>
        <Col span={2}>
          <Tooltip placement="topLeft" title="Send Message">
            <StyledButton
              type="secondary"
              onClick={onClickSendMessageButton}
              icon={sendMessageLoading ? <LoadingOutlined /> : <SendOutlined />}
              isDisabled={!isAssignedPhysician}
            />
          </Tooltip>
        </Col>
        <Col span={3}>
          <Dropdown
            menu={{
              items: [
                { key: TicketStatus.CLOSED, label: 'Closed' },
                { key: TicketStatus.SOLVED, label: 'Solved' }
              ],
              onClick: ({ key }) => {
                updateTicketStatus(key);
                markTicketAsRead();
              }
            }}
            icon={<CheckCircleOutlined />}
        >
          <StyledButton isDisabled={!isAssignedPhysician}>Close as<DownOutlined /></StyledButton>
        </Dropdown>
        </Col>
      </Row>
      </RenderTooltip>
    </>
  );
};

export default memo(AdminPhysicianChatListingComponent);
