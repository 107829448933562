import React, { useEffect, useState, useCallback } from "react";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { getTicketChat, sendChatMessage } from "../../api/TicketChatAPI";
import TicketApi from "../../api/CaseTicketsAPI";
import { getUsersListByStatus } from "../../api/UserTypeAPI";
import socket from "../../api/socket";
import auth from "../../auth/authorization";

// import components
import AdminPhysicianChatListingComponent
  from "../../components/AdminPhysicianChatListingComponent/AdminPhysicianChatListingComponent";
import MessageHeaderComponent from "../../components/CaseMessageComponent/MessageHeaderComponent";

const CasePhysicianChat = ({ caseDetails, userDetails }) => {
  const [messageListLoading, setMessageListLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [assignedRepEmail, setAssignedRepEmail] = useState('')
  const [ticketId, setTicketId] = useState();
  const [admins, setAdmins] = useState([]);

  const userEmail = window.localStorage.getItem("userEmail");
  const { physicianName, caseId, patient, photo } = caseDetails;

  useEffect(() => {
    socket.on("newTicketChatMessageRecieved", (data) => {
      const { users, caseId: updatedCaseId, messageData } = data;
      const isSameCase = updatedCaseId === caseId
      if (isSameCase && users?.includes(auth.getUserId())) {
        const newMessage = { ...messageData, created: messageData.createAt }
        setMessageList(prevList => {
          if (!prevList.length) {
            setTicketId(newMessage.ticketId)
            setAssignedRepEmail(newMessage.chatOwnerDetails.email)
          }
          return prevList.concat(newMessage)
        })
      }
    });
}, [caseId])

  const fetchAdmins = async () => {
    try {
      const { data } = await getUsersListByStatus('active')
      if (!data.payload?.length) {
        message.warn("No active admin users found");
        return;
      }
      setAdmins(data.payload);
    } catch (error) {
      console.log("Error fetching admin users", error);
      message.error("Unable to fetch admin users");
    }
  };

  const fetchMessageList = useCallback(() => {
    if (!caseId) {
      return;
    }
    setMessageListLoading(true);
    getTicketChat({ caseId })
      .then((res) => {
        if (!res.data.success) {
          throw Error("Unable to fetch messages");
        }
        const messages = res.data.payload.data
        setMessageList(messages.reverse());
        if (messages.length) {
          setAssignedRepEmail(messages[0].ticket.rep.email)
          setTicketId(messages[0].ticketId)
        }
      })
      .catch((err) => {
        console.log("======= Chat Room Error :: Fetch messages =======", err);
        message.error(err.response?.data.message || "Unable to fetch messages");
      })
      .finally(() => {
        setMessageListLoading(false);
      });
  }, [caseId]);

  const sendMessage = (text) => {
    return sendChatMessage({ message: text, caseId })
      .then(() => true)
      .catch((err) => {
        console.log("======== Chat Room Error :: Add messages ========", err);
        message.error(err.response?.data.message || "Unable to send message");
      });
  };

  useEffect(() => {
    fetchMessageList();
    fetchAdmins();
  }, [fetchMessageList]);

  const updateTicketStatus = async (status) => {
    try {
      await TicketApi.updateTicket({ status, ticketId })
      message.success(`Ticket successfully closed as ${status}`)
    } catch (e) {
      message.error('Error closing ticket')
    }
  }

  const markTicketAsRead = useCallback(async () => {
    try {
      await TicketApi.markTicketAsRead(ticketId)
      setMessageList(list => list.map(message => ({
        ...message,
        readBy: message.readBy.includes('admin') ? message.readBy : [...message.readBy, 'admin'],
      })));
    } catch (e) {
      console.log('Error marking ticket as read')
    }
  }, [ticketId])

  const patientName = patient ? ((patient.firstName || "") + " " + (patient.lastName || "")).trim() : "";

  return (
    <>
      <Spin
        size="large"
        wrapperClassName="height-hundred"
        spinning={messageListLoading}
        indicator={<LoadingOutlined spin />}
      >
        <MessageHeaderComponent
          avatarUrl={photo}
          physicianName={physicianName}
          patientName={patientName}
          userDetails={userDetails}
          assignedRepEmail={assignedRepEmail}
          setAssignedRepEmail={setAssignedRepEmail}
          caseId={caseId}
          ticketId={ticketId}
          admins={admins}
        />
        <AdminPhysicianChatListingComponent
          authorEmail={userEmail}
          messages={messageList}
          sendMessage={sendMessage}
          updateTicketStatus={updateTicketStatus}
          markTicketAsRead={markTicketAsRead}
          isAssignedPhysician={!!physicianName}
        />
      </Spin>
    </>
  );
};

export default CasePhysicianChat;
