import axios from 'axios';
import { message } from 'antd';

import config from '../config';
import { socket_close } from './socket';

const { api } = config;

//Creating Instance of axios
const axiosInstance = axios.create({
  baseURL: api.baseUrl,
  headers: {
    'X-User-Type': 'admin'
  },
});


//function to check whether request and response can be intercepted for modification
const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled
    ? false
    : true;
};


// Intercepting the request for adding headers and common query params
const requestHandler = request => {
  if (isHandlerEnabled(request)) {
    // Modify request here
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      request.headers.Authorization = `Bearer ${userToken}`
    }

    const { url } = request;
    if (url === "/user/password-reset") {
      const resetToken = localStorage.getItem('resetToken');
      if (resetToken) {
        request.headers.Authorization = `Bearer ${resetToken}`
      }
    }
  }
  return request;
};

axiosInstance.interceptors.request.use(request => requestHandler(request));


// Error handler for response
const errorHandler = (error, response) => {
  if (isHandlerEnabled(error.config)) {
    // Handle errors
    const { response } = error;
    if (response) {
      const { status } = response
      if (status && status === 500) {
        console.error(":::::::::::::::::::::::::::::::: Response :: Error :: ", response, " ::::::::::::::::::::::::::::::::");
        message.error('Sorry! Error occured while performing the requested operation');
      } else if (status && status === 401) {
        const { config } = response;
        let url = "";
        if (config) {
          url = config.url;
        }
        if (url !== "/api/user/login" && url !== "/user/password-reset") {
          message.error('Login session expired. Please login again!');
          localStorage.clear();
          socket_close();
          window.location.replace('/login');
        }
        console.error(":::::::::::::::::::::::::::::::: Response :: Error :: ", response, " ::::::::::::::::::::::::::::::::");
      } else {
        console.error(":::::::::::::::::::::::::::::::: Response :: Error :: ", response, " ::::::::::::::::::::::::::::::");
      }
    } else {
      console.log(":::::::::::::::::::::::::::::::: Response :: Error :: ", response, " ::::::::::::::::::::::::::::::::");
    }
  }
  return Promise.reject({ ...error });
};


// Success Handler for response - Intercept the response and perform status checks
const successHandler = response => {
  if (isHandlerEnabled(response.config)) {
    if (!response) {
      message.error('Sorry! Error occured while performing the requested operation');
    }
    // handle success response
  }
  return response;
};

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

axiosInstance.defaults.withCredentials = true;

export default axiosInstance;
