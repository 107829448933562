import React, {useState} from "react";
import {Button, Modal, Select} from "antd";

export const ReOrderLabModal = ({currentLab, isOpened, onOk, onCancel}) => {
    const [labsCompany, setLabsCompany] = useState(currentLab)

    const options = [
        {label: 'LabCorp', value: 'LabCorp'},
        {label: 'Quest', value: 'Quest'},
    ];

    return (
        <Modal
            title={`Lab Order Reset`}
            destroyOnClose
            width={400}
            open={isOpened}
            onOk={onOk}
            onCancel={onCancel}
            footer={
                <>
                    <Button type="primary" onClick={() => onCancel()}>Cancel</Button>
                    <Button type="primary" onClick={() => onOk(labsCompany)}>Confirm</Button>
                </>
            }
        >
            <Select
                style={{width: '100%'}}
                options={options} 
                value={labsCompany} 
                onChange={(lab) => {setLabsCompany(lab)}}
            />
        </Modal>
    )
}