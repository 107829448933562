import React from "react";
import styled from "styled-components";
import { Typography, Tooltip } from "antd";
import { StyledText } from "../LayoutComponent";
import CaseAssignComponent from "./CaseAssignComponent";

const { Text, Title } = Typography;

const RenderTooltip = ({ children, show, ...tooltipProps }) => {
  if (!show) {
    return children;
  }
  return (
    <Tooltip {...tooltipProps}>
        <div>
            {children}
        </div>
    </Tooltip>
  )
}

const withDisable = (WrapperComponent) => {
    return ({ isDisabled, ...props }) => {

        const StyledComponent = styled.span`
            display: flex;
            gap: 10px;
            ${ isDisabled ? `pointer-events: none; opacity: 0.5;` : `` }
        `;

        return (
            <RenderTooltip show={isDisabled} placement='top' trigger='hover' title="Send first Message to reassign the Ticket">
                <StyledComponent>
                    <WrapperComponent { ...props }/>
                </StyledComponent>
            </RenderTooltip>
        );
    };
}

const StyledHeaderWrapper = styled.div`
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
`;

const StyledSubTitleWrapper = styled.div`
  display: flex;
  gap: 30px;
  width:100%;
`;

const Assignment = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
  text-transform: capitalize;
`

const AssignComponent = withDisable(CaseAssignComponent);

const MessageHeaderComponent = ({
  ticketId,
  caseId,
  physicianName,
  patientName,
  assignedRepEmail,
  userDetails,
  admins,
  setAssignedRepEmail,
}) => {
    return (
        <StyledHeaderWrapper>
            <Title level={ 4 }>Case Id : { caseId }</Title>
            <StyledSubTitleWrapper>
                <Text>
                    Physician : <StyledText>{ physicianName }</StyledText>
                </Text>
                <Text>
                    Patient : <StyledText>{ patientName }</StyledText>
                </Text>
                <Assignment>
                    <AssignComponent
                        currentAdminInfo={ userDetails }
                        ticketId={ ticketId }
                        assignedRepEmail={ assignedRepEmail }
                        admins={ admins }
                        setAssignedRepEmail={ setAssignedRepEmail }
                        isDisabled={!ticketId}
                    />
                </Assignment>
            </StyledSubTitleWrapper>
        </StyledHeaderWrapper>
    );
};

export default MessageHeaderComponent;
