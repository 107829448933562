/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Layout,
  Form,
  Button,
  Row,
  Spin,
  Col,
  message,
  Typography,
} from "antd";
import { Editor } from "@hugerte/hugerte-react";
import config from "../../config";

import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { StyledText } from "../../components/LayoutComponent";
import { useState } from "react";
import { useParams, useHistory } from "react-router";

import { articleView } from "../../api/LmsAPI";
import { EditorContainer } from "./styled";

const { Content } = Layout;
const { Title } = Typography;

const StyledBackButton = styled(Button)`
  margin-bottom: 30px;
  background-color: white;
`;

const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 24px auto;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1200px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%; 
  padding: 24px 28px;
`;

const ArticleView = () => {
  const [editorData, setArticleData] = useState();
  const [articleViewLoading, setArticleViewLoading] = useState(false);
  const {apiKeys}=config;
  let history = useHistory();
  let { id } = useParams();

  const onBackButtonClick = () => {
    history.push("/articles");
  };

  useEffect(() => {
    viewArticleById();
  }, []);

  const viewArticleById = () => {
    setArticleViewLoading(true);
    articleView(id)
      .then((res) => {
        setArticleViewLoading(false);
        const { data } = res;
        if (data && data.payload) {
          setArticleData(data.payload);
        } else {
          message.error("Unable to load article details,Please try agian!");
        }
      })
      .catch(() => {
        setArticleViewLoading(false);
        message.error("Unable to load article details,Please try agian!");
      });
  };

  return (
    <Spin spinning={articleViewLoading} icon={<LoadingOutlined />}>
      <Content>
        <StyledTabWrapper>
          <StyledBackButton size="large" onClick={() => onBackButtonClick()}>
            <LeftOutlined />
            Back to Articles
          </StyledBackButton>

          <StyledComponentWrapper>
            <Form name="article details">
              <Row gutter={24}>
                <Col span={24}>
                  <Title level={3}>
                    Article Id : {editorData && editorData._id}
                  </Title>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Title">
                    <StyledText strong>
                      {editorData && editorData.title}
                    </StyledText>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Article Category">
                    <StyledText strong>
                      {editorData && editorData.articleCategoryName}
                    </StyledText>
                  </Form.Item>
                </Col>
                {(editorData&&editorData.articleCategoryName==="Clinical Protocol")&&
                <Col span={24}>
                  <Form.Item label="Telemedicine Category">
                    <StyledText strong>
                      {editorData && editorData.teleMedicineCategoryName}
                    </StyledText>
                  </Form.Item>
                </Col>}
                <Col span={24}>
                  <Form.Item label="Short Description">
                    <StyledText strong>
                      {editorData && editorData.description}
                    </StyledText>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <EditorContainer inline>
              <Editor
                initialValue={editorData ? editorData.editorHtmlData : ""}
                disabled
                init={{
                  height: 600,
                  inline: true,
                  menubar: "false",
                  plugin: "false",
                  statusbar: false,
                  toolbar: false,
                }}
              />
            </EditorContainer>
          </StyledComponentWrapper>
        </StyledTabWrapper>
      </Content>
    </Spin>
  );
};

export default ArticleView;
